import React, { useState } from 'react';
import BaseForm from './BaseForm';
import axios from 'axios';

const PitchSubmissionForm = ({ onClose }) => {
  const [formData, setFormData] = useState({
    projectTitle: '',
    genre: '',
    logline: '',
    synopsis: '',
    director: '',
    producer: '',
    budget: '',
    contactName: '',
    email: '',
    phone: '',
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    if (errors[e.target.name]) {
      setErrors({ ...errors, [e.target.name]: '' });
    }
  };

  const validateForm = () => {
    let newErrors = {};
    if (!formData.projectTitle) newErrors.projectTitle = 'Project title is required';
    if (!formData.synopsis) newErrors.synopsis = 'Synopsis is required';
    if (!formData.email) newErrors.email = 'Email is required';
    else if (!/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = 'Email is invalid';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsSubmitting(true);
      setError('');
      setSuccess('');

      try {
        const response = await axios.post(`https://api.africafilmfinanceforum.com/pitch-submission`, formData);
        setSuccess('Thank you for submitting your pitch. We will review your submission and get back to you soon.');
        setTimeout(() => onClose(), 3000);
      } catch (error) {
        setError('Submission failed. Please try again later.');
        console.error('Pitch submission error:', error);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <BaseForm title="Pitch Submission" onClose={onClose}>
      <form onSubmit={handleSubmit} className="space-y-4">
        {Object.entries(formData).map(([key, value]) => (
          <div key={key}>
            <label htmlFor={key} className="block text-sm font-medium text-gray-700 mb-1 uppercase">
              {key.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}
            </label>
            {key === 'synopsis' || key === 'logline' ? (
              <textarea
                name={key}
                id={key}
                value={value}
                onChange={handleChange}
                rows={key === 'synopsis' ? '4' : '2'}
                className={`form-input w-full ${errors[key] ? 'border-red-500' : ''}`}
                required
              />
            ) : (
              <input
                type={key === 'email' ? 'email' : key === 'phone' ? 'tel' : key === 'budget' ? 'number' : 'text'}
                name={key}
                id={key}
                value={value}
                onChange={handleChange}
                className={`form-input w-full ${errors[key] ? 'border-red-500' : ''}`}
                required
              />
            )}
            {errors[key] && <p className="mt-1 text-red-500 text-sm">{errors[key]}</p>}
          </div>
        ))}
        {error && <p className="text-red-500">{error}</p>}
        {success && <p className="text-green-500">{success}</p>}
        <div className="flex justify-end space-x-4 mt-6">
          <button
            type="button"
            onClick={onClose}
            className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-500"
          >
            CANCEL
          </button>
          <button
            type="submit"
            disabled={isSubmitting}
            className={`px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            {isSubmitting ? 'SUBMITTING...' : 'SUBMIT PITCH'}
          </button>
        </div>
      </form>
    </BaseForm>
  );
};

export default PitchSubmissionForm;