import React, { useState } from 'react';

const PaymentStep = ({ amount, onBack, onSubmit, currency }) => {
  const [paymentMethod, setPaymentMethod] = useState('creditCard');
  const [cardDetails, setCardDetails] = useState({
    cardNumber: '',
    expiryDate: '',
    cvv: '',
    name: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCardDetails(prevDetails => ({
      ...prevDetails,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ paymentMethod, ...cardDetails });
  };

  const formatCurrency = (amount) => {
    return `${currency.symbol}${amount.toLocaleString(undefined, {maximumFractionDigits: 0})}`;
  };

  return (
    <div className="p-6">
      <h2 className="text-2xl font-bold mb-4">Payment Information</h2>
      <div className="mb-4">
        <p className="font-bold">Total Amount: {formatCurrency(amount)}</p>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block mb-2">Payment Method</label>
          <select
            value={paymentMethod}
            onChange={(e) => setPaymentMethod(e.target.value)}
            className="w-full border p-2 rounded"
          >
            <option value="creditCard">Credit Card</option>
            <option value="paypal">PayPal</option>
            <option value="bankTransfer">Bank Transfer</option>
          </select>
        </div>
        {paymentMethod === 'creditCard' && (
          <>
            <div className="mb-4">
              <label className="block mb-2">Card Number</label>
              <input
                type="text"
                name="cardNumber"
                value={cardDetails.cardNumber}
                onChange={handleInputChange}
                placeholder="1234 5678 9012 3456"
                className="w-full border p-2 rounded"
                required
              />
            </div>
            <div className="flex mb-4">
              <div className="w-1/2 mr-2">
                <label className="block mb-2">Expiry Date</label>
                <input
                  type="text"
                  name="expiryDate"
                  value={cardDetails.expiryDate}
                  onChange={handleInputChange}
                  placeholder="MM/YY"
                  className="w-full border p-2 rounded"
                  required
                />
              </div>
              <div className="w-1/2 ml-2">
                <label className="block mb-2">CVV</label>
                <input
                  type="text"
                  name="cvv"
                  value={cardDetails.cvv}
                  onChange={handleInputChange}
                  placeholder="123"
                  className="w-full border p-2 rounded"
                  required
                />
              </div>
            </div>
            <div className="mb-4">
              <label className="block mb-2">Name on Card</label>
              <input
                type="text"
                name="name"
                value={cardDetails.name}
                onChange={handleInputChange}
                placeholder="John Doe"
                className="w-full border p-2 rounded"
                required
              />
            </div>
          </>
        )}
        {paymentMethod === 'paypal' && (
          <p className="mb-4">You will be redirected to PayPal to complete your payment.</p>
        )}
        {paymentMethod === 'bankTransfer' && (
          <p className="mb-4">Bank transfer details will be provided after you submit your order.</p>
        )}
        <div className="flex justify-between">
          <button
            type="button"
            onClick={onBack}
            className="bg-gray-300 text-black px-4 py-2 rounded hover:bg-gray-400 transition duration-300"
          >
            Back
          </button>
          <button
            type="submit"
            className="bg-custom-red text-white px-4 py-2 rounded hover:bg-red-600 transition duration-300"
          >
            Complete Payment
          </button>
        </div>
      </form>
    </div>
  );
};

export default PaymentStep;