import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import Logo from "../assets/AFFFA1.png";
import okhla from "../assets/okhma-logo.svg";
import { Phone, Mail, Share2, MessageCircle, Image, Linkedin } from 'lucide-react';

const Footer = () => {
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleSubscribe = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError("");
    setSuccess("");

    try {
      const response = await axios.post(`https://api.africafilmfinanceforum.com/subscribe`, { email });
      setSuccess("You're In! We'll keep you updated!");
      setEmail("");
    } catch (error) {
      setError("Subscription failed. Please try again later.");
      console.error('Newsletter subscription error:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <footer className="bg-[#A18749] text-black">
      <div className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {/* Logo and Contact Info */}
          <div className="flex flex-col items-center md:items-start">
            <img
              className="w-32 h-auto mb-4"
              src={Logo}
              alt="AFFF Logo"
            />
            <div className="text-center md:text-left">
              <h3 className="text-xl font-bold mb-2 font-roboto">ENQUIRIES</h3>
              <p className="flex items-center justify-center md:justify-start mb-2">
                <Phone size={20} className="mr-2" />
                <a href="tel:+2348035657616" className="mr-2">+234 803 565 7616</a> |
                <a href="tel:+2348146558068" className="ml-2">+234 814 655 8068</a>
              </p>
              <h3 className="text-xl font-bold mb-2 font-roboto">EMAIL</h3>
              <p className="flex items-center justify-center md:justify-start">
                <Mail size={20} className="mr-2" />
                <a href="mailto:project@africafilmfinanceforum.com" className="underline">
                  project@africafilmfinanceforum.com
                </a>
              </p>
            </div>
          </div>

          {/* Navigation Links */}
          <div className="hidden lg:block font-lexend">
            <h3 className="text-xl font-bold mb-4 font-roboto">Quick Links</h3>
            <div className="grid grid-cols-2 gap-4">
              <ul className="space-y-2">
                <li><Link to="/about" className="hover:underline">ABOUT</Link></li>
                <li><Link to="/programmes" className="hover:underline">PROGRAMMES</Link></li>
                <li><Link to="/attend" className="hover:underline">ATTEND</Link></li>
              </ul>
              <ul className="space-y-2">
                <li><Link to="/exhibit/accredited-companies" className="hover:underline">EXHIBIT</Link></li>
                <li><Link to="/sponsor/opportunities" className="hover:underline">SPONSOR</Link></li>
                <li><Link to="/contact" className="hover:underline">CONTACT</Link></li>
              </ul>
            </div>
          </div>

          {/* Newsletter Subscription */}
          <div className="flex flex-col items-center lg:items-start">
            <h3 className="text-xl font-bold mb-2 text-center lg:text-left font-roboto">Stay Updated</h3>
            <p className="text-sm mb-4 text-center lg:text-left max-w-xs">
              Subscribe to our newsletter for the latest news and insights about the Africa Film Finance Forum.
            </p>
            <form onSubmit={handleSubscribe} className="w-full max-w-md">
              <div className="flex">
                <input
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="flex-grow px-3 py-2 rounded-l-md focus:outline-none"
                  required
                />
                <button 
                  type="submit" 
                  disabled={isSubmitting}
                  className={`bg-[#594A28] text-white px-4 py-2 rounded-r-md font-semibold ${isSubmitting ? 'opacity-50 cursor-not-allowed' : 'hover:bg-[#493d22]'}`}
                >
                  {isSubmitting ? 'SUBSCRIBING...' : 'SUBSCRIBE'}
                </button>
              </div>
            </form>
            {error && <p className="mt-2 text-red-500 text-sm">{error}</p>}
            {success && <p className="mt-2 text-green-500 text-sm">{success}</p>}
          </div>
        </div>

        {/* Mobile Navigation */}
        <div className="mt-8 lg:hidden">
          <ul className="flex flex-wrap justify-center gap-4 font-lexend">
            <li><Link to="/about" className="hover:underline">ABOUT</Link></li>
            <li><Link to="/programmes" className="hover:underline">PROGRAMMES</Link></li>
            <li><Link to="/attend" className="hover:underline">ATTEND</Link></li>
            <li><Link to="/exhibit/accredited-companies" className="hover:underline">EXHIBIT</Link></li>
            <li><Link to="/sponsor/opportunities" className="hover:underline">SPONSOR</Link></li>
            <li><Link to="/contact" className="hover:underline">CONTACT</Link></li>
          </ul>
        </div>
        
      </div>

      {/* Bottom Bar */}
      <div className="border-t border-black mt-8">
        <div className="container mx-auto px-4 py-4 flex flex-col md:flex-row justify-between items-center">
          <div className="flex items-center mb-4 md:mb-0">
            <p className="text-sm mr-2">Organized by</p>
            <a href="https://okhma.com" target="_blank" rel="noopener noreferrer">
              <img
                className="w-24 h-auto"
                src={okhla}
                alt="Okhma"
              />
            </a>
          </div>
          <p className="text-sm text-center md:text-right">
            Copyright © 2024 African Film Finance Festival | An Okhma Global Initiative
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;