import React, { useState } from 'react';
import NavigationBar from "../component/NavigationBar";
import Footer from "../component/Footer";
import headerImage from "/pages-bg/3.png";
import Pricing from "../component/Pricing";
import EventCards from "../component/EventCards";
import FinancierAccreditationForm from "../component/FinancierAccreditationForm"; // Import the correct form

import salesProductionImage from '../assets/sales-production.jpeg';
import pitchImage from '../assets/pitching.webp';
import marketReviewImage from '../assets/market-review.jpg';
import investorsLoungeImage from '../assets/lounge.jpg';
import dealTableImage from "../assets/dealtable.jpg";
import investorsRoomImage from '../assets/investors-room.jpg';

const Attend = ({ setShowPricingModal }) => {
  const [isAccreditationFormOpen, setIsAccreditationFormOpen] = useState(false);

  const openFinancierAccreditationForm = () => {
    setIsAccreditationFormOpen(true);
  };

  const closeFinancierAccreditationForm = () => {
    setIsAccreditationFormOpen(false);
  };

  const cardData = [
    {
      title: "Sales & Production",
      content: "AFFF will feature accredited production and sales companies from Africa and beyond, presenting over a thousand film projects to a diverse group of financiers.",
      image: salesProductionImage,
      link: "/exhibit/accredited-companies"
    },
    {
      title: "Screening & Pitch Sessions",
      content: "The AFFF forum spanning three days will host dynamic blend of screening sessions featuring a mix of released and unreleased films.",
      image: pitchImage,
      link: "/programmes/pitch-session"
    },
    {
      title: "Market Review Session",
      content: "The market review session will provide a comprehensive overview of the African film industry, tracing its origins, examining its current state, and envisioning its future potential.",
      image: marketReviewImage,
      link: "/programmes/market-review"
    },
    {
      title: "Investors Lounge",
      content: "AFFF Investors Lounge, the ultimate hub for relaxation and rejuvenation between screenings and meetings.",
      image: investorsLoungeImage,
      link: "/programmes"
    },
    {
      title: "Deal Table",
      content: "The Deal Table is an ultra-exclusive boardroom setting for high-budget film projects with significant ROI potential.",
      image: dealTableImage,
      link: "/exhibit/deal-table"
    },
    {
      title: "Investors Room",
      content: "The Investor Room is an exclusive space where accredited sales and production companies present their film projects to financiers for investment opportunities.",
      image: investorsRoomImage,
      link: "/exhibit/investors-room"
    },
  ];

  return (
    <div className="relative">
      <header className="relative">
        <header className="relative w-full h-[300px] sm:h-[450px] overflow-hidden">
          <img 
            src={headerImage} 
            alt="AFFF Attend" 
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-[#556C95] opacity-50"></div>
          <div className="absolute inset-0 flex flex-col items-center justify-center pt-5">
            <h2 className="font-header text-4xl sm:text-5xl md:text-[4rem] tracking-wider text-white">FINANCIERS</h2>
            <p className="text-xl sm:text-base md:text-2xl font-lexend text-gray-300">[These are the films you can be assured of your ROI]</p>
          </div>
        </header>
        
        <div className="absolute top-0 left-0 right-0 z-10">
          <NavigationBar />
        </div>
      </header>

      <main className="bg-white mb-12 sm:mb-24 md:mb-48">
        <div className="container mx-auto px-4 py-8 sm:py-12">
          <section className="mb-8 sm:mb-16">
            <h2 className="font-header text-xl sm:text-2xl md:text-[2rem] text-black tracking-wider uppercase">Get your badge now!!!</h2>
            <p className="text-gray-700 text-base sm:text-lg md:text-[1.3rem]">In these 3 days you can meet face-face with top producers, directors, production companies and attend screenings to get to know new project and learn a lot about the film industry.</p>
          </section>

          <EventCards cardData={cardData} />
           
          <section className="div-with-bold-shadow mt-8 sm:mt-16">
            <div className="border-l-8 border-[#A18749] pl-4 mb-4 sm:mb-6">
              <h2 className="font-header text-[4rem] leading-none sm:text-2xl md:text-6xl font-bold mb-2 tracking-wider text-custom-base">LAGOS BOUND. EXPLORE THE FULL MARKET IN A SINGLE LOCATION</h2>
            </div>
            <p className="text-gray-700 font-semibold text-base sm:text-lg md:text-[1.3rem]">
              Join us this November for the Africa Film Finance Forum at the Lagos Oriental Hotel. With all activities 
              consolidated under one roof, our aim is to deliver a premium event meticulously designed to meet your 
              needs, fostering seamless networking, collaboration, and business opportunities for the global film 
              industry. <br/><br/>
              Our venue offers modern facilities and conveniences for an enhanced market experience. There are 
              offers for guest rooms and suites, as well as designated space for Exhibitors. Additionally, there are 
              specialized areas for meetings and events, complemented by an on-site lounge that serves a variety of 
              food options.
            </p>
          </section>
        </div>
            
        {/* Full-width Pricing section */}
        <section className="w-full bg-[#FAF0E6] py-8 sm:py-12">
          <div className="max-w-[1440px] mx-auto px-4">
            <Pricing setShowPricingModal={setShowPricingModal} />
          </div>
        </section>
         
        <div className="container mx-auto px-4 py-8 sm:py-12">
          <section className="div-with-bold-shadow mt-8 sm:mt-16 flex-col">
            <div className="border-l-8 border-[#A18749] pl-4 ">
              <h2 className="font-lexend text-xl sm:text-2xl md:text-[1.5rem] leading-none font-bold text-custom-base">ACCREDITATION</h2>
            </div>
            <div className="flex flex-col sm:flex-row gap-4 sm:gap-6 mt-4">
              <p className="text-gray-700 font-semibold text-base sm:text-lg md:text-[1.3rem]">To register as a Financier for AFFF 2024, you must be an "AFFF Accredited Film Financier."</p>
              <button 
                onClick={openFinancierAccreditationForm}
                className="bg-custom-red text-white px-4 sm:px-6 py-3 sm:py-4 rounded-tl-lg rounded-br-lg capitalize font-roboto hover:bg-red-600 transition duration-300 text-sm sm:text-base"
              >
                Apply for Accreditation now
              </button>
            </div>
          </section>

          <section className="div-with-bold-shadow mt-8 sm:mt-16 flex-col">
            <div className="border-l-8 border-[#A18749] pl-4 ">
              <h2 className="font-lexend text-xl sm:text-2xl md:text-[1.5rem] leading-none font-bold tracking-wider text-custom-base">ACCOMMODATION</h2>
            </div>
            <div className="mt-4">
              <p className="text-gray-700 font-semibold text-base sm:text-lg md:text-[1.3rem]">Subject to availability, AFFF provide guaranteed rates for guest rooms for registered participants.</p>
            </div>
          </section>

          <section className="div-with-bold-shadow mt-8 sm:mt-16 flex-col mb-7">
            <div className="border-l-8 border-[#A18749] pl-4 ">
              <h2 className="font-lexend text-xl sm:text-2xl md:text-[1.5rem] leading-none font-bold text-custom-base">ACCREDITATION LIAISON</h2>
            </div>
            <div className="flex flex-col sm:flex-row gap-4 sm:gap-6 mt-4">
              <p className="text-gray-700 font-semibold text-base sm:text-lg md:text-[1.3rem]">
                AFFF provides specialized financier liaisons who are committed to assisting with the accreditation 
                process. Our dedicated team will be available to help you both before and during AFFF, ensuring that all 
                your inquiries are addressed. Reach out to us via email at:
              </p>
              <a 
                href="mailto:projects@africafilmfinanceforum.com"
                className="bg-custom-red text-white px-4 sm:px-6 py-3 sm:py-4 rounded-tl-lg rounded-br-lg capitalize font-roboto hover:bg-red-600 transition duration-300 text-sm sm:text-base break-all sm:break-normal"
              >
                projects@africafilmfinanceforum.com
              </a>
            </div>
          </section>
        </div>
      </main>
      
      <Footer />

      {/* Financier Accreditation Form */}
      {isAccreditationFormOpen && (
        <FinancierAccreditationForm onClose={closeFinancierAccreditationForm} />
      )}
    </div>
  );
};

export default Attend;