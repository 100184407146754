// RegistrationForm.jsx
import React, { useState } from 'react';
import BaseForm from './BaseForm';
import axios from 'axios';

const RegistrationForm = ({ onClose }) => {
  const [formData, setFormData] = useState({
    companyName: '',
    contactName: '',
    contactDesignation: '',
    companyAddress: '',
    city: '',
    state: '',
    country: '',
    zipCode: '',
    email: '',
    phone: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError('');
    setSuccess('');

    try {
      const response = await axios.post(`https://api.africafilmfinanceforum.com/register`, formData);
      setSuccess('Registration successful! Check your email for confirmation.');
      setTimeout(() => onClose(), 3000);
    } catch (error) {
      setError('Registration failed. Please try again later.');
      console.error('Registration error:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <BaseForm title="AFFF Registration Form" onClose={onClose}>
      <form onSubmit={handleSubmit} className="space-y-4">
        {Object.keys(formData).map((key) => (
          <div key={key}>
            <label htmlFor={key} className="block text-sm font-medium text-gray-700 uppercase">
              {key.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}
            </label>
            <input
              type={key === 'email' ? 'email' : 'text'}
              name={key}
              id={key}
              value={formData[key]}
              onChange={handleChange}
              className="form-input w-full"
              required
            />
          </div>
        ))}
        {error && <p className="text-red-500">{error}</p>}
        {success && <p className="text-green-500">{success}</p>}
        <div>
          <button
            type="submit"
            disabled={isSubmitting}
            className={`bg-custom-red text-white px-4 py-2 rounded-tl-lg rounded-br-lg hover:bg-red-600 transition duration-300 w-full sm:w-auto ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            {isSubmitting ? 'Submitting...' : 'Submit'}
          </button>
        </div>
      </form>
    </BaseForm>
  );
};

export default RegistrationForm;