import React, { useState } from "react";
import { Link } from "react-router-dom";
import AFFlogo from "../assets/AFFlogo.png";
import RegistrationForm from './RegistrationForm';

function NavigationBar() {
  const [isOpen, setIsOpen] = useState(false);
  const [showRegistrationForm, setShowRegistrationForm] = useState(false);

  const openMenu = () => {
    setIsOpen(true);
    document.body.style.overflow = "hidden";
  };

  const closeMenu = () => {
    setIsOpen(false);
    document.body.style.overflow = "auto";
  };

  const openRegistrationForm = () => {
    setShowRegistrationForm(true);
    closeMenu();
  };

  return (
    <header className="px-[1rem] font-nav py-6 h-31 md:px-[4rem] lg:px-[6rem]">
      <div className="flex items-center px-4 py-1 justify-between relative z-50 bg-custom-white rounded-lg shadow-md lg:py-2">
        <div>
          <Link to="/">
            <img className="w-[120px]" src={AFFlogo} alt="Africa Film Finance Forum" />
          </Link>
        </div>
        <div className="lg:hidden">
          <button onClick={openMenu} className="focus:outline-none">
            <svg width="33" height="21" viewBox="0 0 33 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2 0C1.17157 0 0.5 0.671573 0.5 1.5C0.5 2.32843 1.17157 3 2 3V0ZM2 3H33V0H2V3Z" fill="#867F7F" />
              <path d="M2 9C1.17157 9 0.5 9.67157 0.5 10.5C0.5 11.3284 1.17157 12 2 12V9ZM2 12H33V9H2V12Z" fill="#867F7F" />
              <path d="M2 18C1.17157 18 0.5 18.6716 0.5 19.5C0.5 20.3284 1.17157 21 2 21V18ZM2 21H33V18H2V21Z" fill="#867F7F" />
            </svg>
          </button>
        </div>
        <div className="hidden lg:block lg:flex lg:items-center lg:gap-6">
          <ul className="flex gap-6 font-lexend tracking-wide text-custom-base text-[16px]">
            <li className="relative group">
              <button className="hover:opacity-50 cursor-pointer">ATTEND</button>
              <div className="absolute top-0 -left-30 transition group-hover:translate-y-5 translate-y-0 opacity-0 invisible group-hover:opacity-100 group-hover:visible duration-500 ease-in-out group-hover:transform z-50 min-w-[400px] transform">
                <div className="relative top-6 p-6 bg-custom-subnav rounded-xl shadow-xl w-full">
                  <div className="w-10 h-10 bg-custom-subnav transform rotate-45 absolute top-0 left-[-11rem] z-0 translate-x-0 transition-transform group-hover:translate-x-[12rem] duration-300 ease-in-out rounded-sm"></div>
                  <div className="relative text-custom-navcolor text-sm font-body">
                    <Link to="/attend" className="block hover:border-b-2 hover:border-custom-red hover:text-custom-red hover:text-[15px] hover:tracking-wider transition-all duration-300"><p>ACCREDITED FINANCIER</p></Link>
                    <Link to="/financier-accreditation" className="block mt-1 hover:border-b-2 hover:border-custom-red hover:text-custom-red hover:text-[15px] hover:tracking-wider transition-all duration-300"><p>APPLY FOR FINANCIER ACCREDITATION</p></Link>
                    <Link to="/attendees" className="block mt-1 hover:border-b-2 hover:border-custom-red hover:text-custom-red hover:text-[15px] hover:tracking-wider transition-all duration-300"><p>ATTENDEES</p></Link>
                    <Link to="/who-should-attend" className="block mt-1 hover:border-b-2 hover:border-custom-red hover:text-custom-red hover:text-[15px] hover:tracking-wider transition-all duration-300"><p>WHO SHOULD ATTEND</p></Link>
                    <Link to="/accommodation" className="block mt-1 hover:border-b-2 hover:border-custom-red hover:text-custom-red hover:text-[15px] hover:tracking-wider transition-all duration-300"><p>ACCOMMODATION</p></Link>
                  </div>
                </div>
              </div>
            </li>
            <li className="relative group">
              <button className="hover:opacity-50 cursor-pointer">EXHIBIT</button>
              <div className="absolute top-0 -left-30 transition group-hover:translate-y-5 translate-y-0 opacity-0 invisible group-hover:opacity-100 group-hover:visible duration-500 ease-in-out group-hover:transform z-50 min-w-[450px] transform">
                <div className="relative top-6 p-6 bg-custom-subnav rounded-xl shadow-xl w-full">
                  <div className="w-10 h-10 bg-custom-subnav transform rotate-45 absolute top-0 left-[-11rem] z-0 translate-x-0 transition-transform group-hover:translate-x-[12rem] duration-300 ease-in-out rounded-sm"></div>
                  <div className="relative text-custom-navcolor text-sm font-body">
                    <Link to="/exhibit/accredited-companies" className="block hover:border-b-2 hover:border-custom-red hover:text-custom-red hover:text-[15px] hover:tracking-wider transition-all duration-300"><p>ACCREDITED FILM PRODUCTION & SALES COMPANIES</p></Link>
                    <Link to="/exhibit/how-to-exhibit" className="block mt-1 hover:border-b-2 hover:border-custom-red hover:text-custom-red hover:text-[15px] hover:tracking-wider transition-all duration-300"><p>HOW TO EXHIBIT</p></Link>
                    <Link to="/exhibit/who-can-exhibit" className="block mt-1 hover:border-b-2 hover:border-custom-red hover:text-custom-red hover:text-[15px] hover:tracking-wider transition-all duration-300"><p>WHO SHOULD EXHIBIT</p></Link>
                    <Link to="/exhibit/deal-table" className="block mt-1 hover:border-b-2 hover:border-custom-red hover:text-custom-red hover:text-[15px] hover:tracking-wider transition-all duration-300"><p>DEAL TABLE</p></Link>
                    <Link to="/exhibit/investors-room" className="block mt-1 hover:border-b-2 hover:border-custom-red hover:text-custom-red hover:text-[15px] hover:tracking-wider transition-all duration-300"><p>INVESTORS ROOM</p></Link>
                    <Link to="/programmes/pitch-sessions" className="block mt-1 hover:border-b-2 hover:border-custom-red hover:text-custom-red hover:text-[15px] hover:tracking-wider transition-all duration-300"><p>PITCH SESSION</p></Link>
                    <Link to="/exhibit/cinetour-expo" className="block mt-1 hover:border-b-2 hover:border-custom-red hover:text-custom-red hover:text-[15px] hover:tracking-wider transition-all duration-300"><p>FILM COMMISSION & PRODUCTION SERVICES</p></Link>
                  </div>
                </div>
              </div>
            </li>
            <li className="relative group">
              <button className="hover:opacity-50 cursor-pointer">SPONSOR</button>
              <div className="absolute top-0 -left-30 transition group-hover:translate-y-5 translate-y-0 opacity-0 invisible group-hover:opacity-100 group-hover:visible duration-500 ease-in-out group-hover:transform z-50 min-w-[300px] transform">
                <div className="relative top-6 p-6 bg-custom-subnav rounded-xl shadow-xl w-full">
                  <div className="w-10 h-10 bg-custom-subnav transform rotate-45 absolute top-0 left-[-11rem] z-0 translate-x-0 transition-transform group-hover:translate-x-[12rem] duration-300 ease-in-out rounded-sm"></div>
                  <div className="relative text-custom-navcolor text-sm font-body">
                    <Link to="/sponsor/opportunities" className="block hover:border-b-2 hover:border-custom-red hover:text-custom-red hover:text-[15px] hover:tracking-wider transition-all duration-300"><p>FINANCIAL INSTITUTIONS</p></Link>
                    <Link to="/sponsor/brands-packages" className="block mt-1 hover:border-b-2 hover:border-custom-red hover:text-custom-red hover:text-[15px] hover:tracking-wider transition-all duration-300"><p>BRANDS THAT SUPPORT FILMS</p></Link>
                    <Link to="/sponsor/benefits" className="block mt-1 hover:border-b-2 hover:border-custom-red hover:text-custom-red hover:text-[15px] hover:tracking-wider transition-all duration-300"><p>OPPORTUNITIES & BENEFITS</p></Link>
                    <Link to="/sponsor/benefits#advert-slots" className="block mt-1 hover:border-b-2 hover:border-custom-red hover:text-custom-red hover:text-[15px] hover:tracking-wider transition-all duration-300"><p>ADVERT SLOTS</p></Link>
                  </div>
                </div>
              </div>
            </li>
            <li className="relative group">
              <button className="hover:opacity-50 cursor-pointer">PROGRAMMES</button>
              <div className="absolute top-0 -left-30 transition group-hover:translate-y-5 translate-y-0 opacity-0 invisible group-hover:opacity-100 group-hover:visible duration-500 ease-in-out group-hover:transform z-50 min-w-[300px] transform">
                <div className="relative top-6 p-6 bg-custom-subnav rounded-xl shadow-xl w-full">
                  <div className="w-10 h-10 bg-custom-subnav transform rotate-45 absolute top-0 left-[-11rem] z-0 translate-x-0 transition-transform group-hover:translate-x-[12rem] duration-300 ease-in-out rounded-sm"></div>
                  <div className="relative text-custom-navcolor text-sm font-body">
                    <Link to="/programmes" className="block hover:border-b-2 hover:border-custom-red hover:text-custom-red hover:text-[15px] hover:tracking-wider transition-all duration-300"><p>FORUM SESSIONS</p></Link>
                    <Link to="/programmes/pitch-sessions" className="block mt-1 hover:border-b-2 hover:border-custom-red hover:text-custom-red hover:text-[15px] hover:tracking-wider transition-all duration-300"><p>PITCH SESSIONS</p></Link>
                    <Link to="/programmes/market-review" className="block mt-1 hover:border-b-2 hover:border-custom-red hover:text-custom-red hover:text-[15px] hover:tracking-wider transition-all duration-300"><p>MARKET REVIEW SESSIONS</p></Link>
                    <Link to="/programmes/master-class" className="block mt-1 hover:border-b-2 hover:border-custom-red hover:text-custom-red hover:text-[15px] hover:tracking-wider transition-all duration-300"><p>MASTERCLASS</p></Link>
                    <Link to="/programmes/forum-speakers" className="block mt-1 hover:border-b-2 hover:border-custom-red hover:text-custom-red hover:text-[15px] hover:tracking-wider transition-all duration-300"><p>FORUM SPEAKERS</p></Link>
                    <Link to="/programmes/special-event" className="block mt-1 hover:border-b-2 hover:border-custom-red hover:text-custom-red hover:text-[15px] hover:tracking-wider transition-all duration-300"><p>SPECIAL EVENTS</p></Link>
                    <Link to="/programmes/film-screening" className="block mt-1 hover:border-b-2 hover:border-custom-red hover:text-custom-red hover:text-[15px] hover:tracking-wider transition-all duration-300"><p>FILM SCREENINGS</p></Link>
                    <Link to="/programmes/film-premier" className="block mt-1 hover:border-b-2 hover:border-custom-red hover:text-custom-red hover:text-[15px] hover:tracking-wider transition-all duration-300"><p>FILM PREMIERS</p></Link>
                  </div>
                </div>
              </div>
            </li>
            <li className="relative group">
              <button className="hover:opacity-50 cursor-pointer">ABOUT</button>
              <div className="absolute top-0 -left-30 transition group-hover:translate-y-5 translate-y-0 opacity-0 invisible group-hover:opacity-100 group-hover:visible duration-500 ease-in-out group-hover:transform z-50 min-w-[300px] transform">
                <div className="relative top-6 p-6 bg-custom-subnav rounded-xl shadow-xl w-full">
                  <div className="w-10 h-10 bg-custom-subnav transform rotate-45 absolute top-0 left-[-11rem] z-0 translate-x-0 transition-transform group-hover:translate-x-[12rem] duration-300 ease-in-out rounded-sm"></div>
                  <div className="relative text-custom-navcolor text-sm font-body">
                    <Link to="/about" className="block hover:border-b-2 hover:border-custom-red hover:text-custom-red hover:text-[15px] hover:tracking-wider transition-all duration-300"><p>ABOUT AFF</p></Link>
                    <Link to="/about/advisors" className="block mt-1 hover:border-b-2 hover:border-custom-red hover:text-custom-red hover:text-[15px] hover:tracking-wider transition-all duration-300"><p>FORUM ADVISORS</p></Link>
                    <Link to="/about/venue" className="block mt-1 hover:border-b-2 hover:border-custom-red hover:text-custom-red hover:text-[15px] hover:tracking-wider transition-all duration-300"><p>THE VENUE</p></Link>
                    <Link to="/about/faq" className="block mt-1 hover:border-b-2 hover:border-custom-red hover:text-custom-red hover:text-[15px] hover:tracking-wider transition-all duration-300"><p>FAQS</p></Link>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <Link 
                to="/register" 
                className="px-6 py-2 cursor-pointer rounded-tl-lg rounded-br-lg border border-orange-500 tracking-wide text-[18px] hover:bg-custom-red hover:transition-all"
                >
                    REGISTER
                </Link>
        </div>
      </div>
      {isOpen && (
        <div className="w-[100%] absolute inset-0 z-50 bg-[#F5F5F5] text-black font-lexend font-normal text-[20px] px-6 py-4 space-y-2 md:text-[40px] lg:hidden">
          <div className="flex justify-end pt-4">
            <button onClick={closeMenu}>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 text-gray-500 hover:text-gray-700 cursor-pointer" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M14.95 4.95a.75.75 0 010 1.06L11.06 10l3.89 3.89a.75.75 0 11-1.06 1.06L10 11.06l-3.89 3.89a.75.75 0 11-1.06-1.06L8.94 10 5.05 6.11a.75.75 0 011.06-1.06L10 8.94l3.89-3.89a.75.75 0 011.06 0z" clipRule="evenodd" />
              </svg>
            </button>
          </div>
          <ul className="flex flex-col gap-8">
            {/* Mobile menu items */}
            <li><Link to="/attend" onClick={closeMenu}>ATTEND</Link></li>
            <li><Link to="/exhibit" onClick={closeMenu}>EXHIBIT</Link></li>
            <li><Link to="/sponsor" onClick={closeMenu}>SPONSOR</Link></li>
            <li><Link to="/programmes" onClick={closeMenu}>PROGRAMMES</Link></li>
            <li><Link to="/about" onClick={closeMenu}>ABOUT</Link></li>
            <li>
              <button onClick={openRegistrationForm} className="hover:opacity-50 cursor-pointer">
                REGISTER
              </button>
            </li>
          </ul>
        </div>
      )}
      {showRegistrationForm && (
        <RegistrationForm onClose={() => setShowRegistrationForm(false)} />
      )}
    </header>
  );
}

export default NavigationBar;