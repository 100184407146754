import React, { useState } from 'react';
import axios from 'axios';

// Assume this array is imported from a separate file
const countries = [
  "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Antigua and Barbuda", "Argentina", "Armenia", "Australia", "Austria",
  // ... (rest of the countries array remains the same)
];

const PersonalInfoForm = ({ cart, onBack, onSubmit }) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    gender: '',
    company: '',
    organizationSize: '',
    industry: '',
    title: '',
    streetAddress: '',
    city: '',
    state: '',
    country: '',
    zipCode: '',
    mobile: '',
    agreeTerms: false,
    agreeSponsors: false,
    billingInfo: {
      firstName: '',
      lastName: '',
      email: '',
      streetAddress: '',
      city: '',
      state: '',
      country: '',
      zipCode: '',
      mobile: '',
    },
    badges: cart.map(() => ({
      firstName: '',
      lastName: '',
      email: '',
      mobile: '',
      company: '',
      position: '',
      streetAddress: '',
      city: '',
      state: '',
      country: '',
      nameOfBadge: '',
      isPlaceholder: false,
      placeholderName: '',
    })),
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (e, section = 'personal', index = null) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevData => {
      if (section === 'personal') {
        return { ...prevData, [name]: type === 'checkbox' ? checked : value };
      } else if (section === 'billing') {
        return { ...prevData, billingInfo: { ...prevData.billingInfo, [name]: value } };
      } else if (section === 'badge' && index !== null) {
        const newBadges = [...prevData.badges];
        newBadges[index] = { ...newBadges[index], [name]: type === 'checkbox' ? checked : value };
        return { ...prevData, badges: newBadges };
      }
      return prevData;
    });
  };

  const copyPersonalToBilling = () => {
    setFormData(prevData => ({
      ...prevData,
      billingInfo: {
        firstName: prevData.firstName,
        lastName: prevData.lastName,
        email: prevData.email,
        streetAddress: prevData.streetAddress,
        city: prevData.city,
        state: prevData.state,
        country: prevData.country,
        zipCode: prevData.zipCode,
        mobile: prevData.mobile,
      },
    }));
  };

  const copyInfoToBadge = (index, source = 'personal') => {
    setFormData(prevData => {
      const newBadges = [...prevData.badges];
      const sourceData = source === 'personal' ? prevData : prevData.billingInfo;
      newBadges[index] = {
        ...newBadges[index],
        firstName: sourceData.firstName,
        lastName: sourceData.lastName,
        email: sourceData.email,
        mobile: sourceData.mobile,
        company: prevData.company,
        position: prevData.title,
        streetAddress: sourceData.streetAddress,
        city: sourceData.city,
        state: sourceData.state,
        country: sourceData.country,
      };
      return { ...prevData, badges: newBadges };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);

    try {
      const response = await axios.post('https://api.africafilmfinanceforum.com/register', {
        personalInfo: formData,
        cart: cart
      });

      if (response.data.success) {
        onSubmit(formData);
      } else {
        setError('Failed to submit registration. Please try again.');
      }
    } catch (error) {
      setError('An error occurred. Please try again later.');
      console.error('Registration error:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6 p-6 font-lexend">
      <div>
        <h3 className="text-xl font-bold mb-4">Your Information</h3>
        <div className="grid grid-cols-2 gap-4">
          <input
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={(e) => handleChange(e)}
            placeholder="First Name"
            required
            className="border p-2 rounded"
          />
          <input
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={(e) => handleChange(e)}
            placeholder="Last Name"
            required
            className="border p-2 rounded"
          />
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={(e) => handleChange(e)}
            placeholder="Email Address"
            required
            className="border p-2 rounded col-span-2"
          />
          <select
            name="gender"
            value={formData.gender}
            onChange={(e) => handleChange(e)}
            required
            className="border p-2 rounded"
          >
            <option value="">Select Gender</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Other</option>
          </select>
          <input
            type="text"
            name="company"
            value={formData.company}
            onChange={(e) => handleChange(e)}
            placeholder="Company"
            className="border p-2 rounded"
          />
          <select
            name="organizationSize"
            value={formData.organizationSize}
            onChange={(e) => handleChange(e)}
            className="border p-2 rounded"
          >
            <option value="">Organization Size</option>
            <option value="1-10">1-10</option>
            <option value="11-50">11-50</option>
            <option value="51-200">51-200</option>
            <option value="201-500">201-500</option>
            <option value="501+">501+</option>
          </select>
          <input
            type="text"
            name="industry"
            value={formData.industry}
            onChange={(e) => handleChange(e)}
            placeholder="Industry"
            className="border p-2 rounded"
          />
          <input
            type="text"
            name="title"
            value={formData.title}
            onChange={(e) => handleChange(e)}
            placeholder="Title (e.g., CEO, Owner)"
            className="border p-2 rounded"
          />
          <input
            type="text"
            name="streetAddress"
            value={formData.streetAddress}
            onChange={(e) => handleChange(e)}
            placeholder="Street Address"
            required
            className="border p-2 rounded col-span-2"
          />
          <input
            type="text"
            name="city"
            value={formData.city}
            onChange={(e) => handleChange(e)}
            placeholder="City"
            required
            className="border p-2 rounded"
          />
          <input
            type="text"
            name="state"
            value={formData.state}
            onChange={(e) => handleChange(e)}
            placeholder="State/Province"
            className="border p-2 rounded"
          />
          <select
            name="country"
            value={formData.country}
            onChange={(e) => handleChange(e)}
            required
            className="border p-2 rounded"
          >
            <option value="">Select Country</option>
            {countries.map((country) => (
              <option key={country} value={country}>
                {country}
              </option>
            ))}
          </select>
          <input
            type="text"
            name="zipCode"
            value={formData.zipCode}
            onChange={(e) => handleChange(e)}
            placeholder="Zip/Postal Code"
            required
            className="border p-2 rounded"
          />
          <input
            type="tel"
            name="mobile"
            value={formData.mobile}
            onChange={(e) => handleChange(e)}
            placeholder="Mobile Number"
            required
            className="border p-2 rounded"
          />
        </div>
      </div>

      <div>
        <h3 className="text-xl font-bold mb-4">Billing Information</h3>
        <button type="button" onClick={copyPersonalToBilling} className="mb-4 text-blue-500 underline">
          Copy from Personal Information
        </button>
        <div className="grid grid-cols-2 gap-4">
          <input
            type="text"
            name="firstName"
            value={formData.billingInfo.firstName}
            onChange={(e) => handleChange(e, 'billing')}
            placeholder="First Name"
            required
            className="border p-2 rounded"
          />
          <input
            type="text"
            name="lastName"
            value={formData.billingInfo.lastName}
            onChange={(e) => handleChange(e, 'billing')}
            placeholder="Last Name"
            required
            className="border p-2 rounded"
          />
          <input
            type="email"
            name="email"
            value={formData.billingInfo.email}
            onChange={(e) => handleChange(e, 'billing')}
            placeholder="Email Address"
            required
            className="border p-2 rounded col-span-2"
          />
          <input
            type="text"
            name="streetAddress"
            value={formData.billingInfo.streetAddress}
            onChange={(e) => handleChange(e, 'billing')}
            placeholder="Street Address"
            required
            className="border p-2 rounded col-span-2"
          />
          <input
            type="text"
            name="city"
            value={formData.billingInfo.city}
            onChange={(e) => handleChange(e, 'billing')}
            placeholder="City"
            required
            className="border p-2 rounded"
          />
          <input
            type="text"
            name="state"
            value={formData.billingInfo.state}
            onChange={(e) => handleChange(e, 'billing')}
            placeholder="State/Province"
            className="border p-2 rounded"
          />
          <select
            name="country"
            value={formData.billingInfo.country}
            onChange={(e) => handleChange(e, 'billing')}
            required
            className="border p-2 rounded"
          >
            <option value="">Select Country</option>
            {countries.map((country) => (
              <option key={country} value={country}>
                {country}
              </option>
            ))}
          </select>
          <input
            type="text"
            name="zipCode"
            value={formData.billingInfo.zipCode}
            onChange={(e) => handleChange(e, 'billing')}
            placeholder="Zip/Postal Code"
            required
            className="border p-2 rounded"
          />
          <input
            type="tel"
            name="mobile"
            value={formData.billingInfo.mobile}
            onChange={(e) => handleChange(e, 'billing')}
            placeholder="Mobile Number"
            required
            className="border p-2 rounded"
          />
        </div>
      </div>

      {cart.map((item, index) => (
        <div key={index}>
          <h3 className="text-xl font-bold mb-4">Badge {index + 1} - {item.name}</h3>
          <button type="button" onClick={() => copyInfoToBadge(index, 'personal')} className="mr-4 text-blue-500 underline">
            Copy from Personal Information
          </button>
          <button type="button" onClick={() => copyInfoToBadge(index, 'billing')} className="text-blue-500 underline">
            Copy from Billing Information
          </button>
          <div className="grid grid-cols-2 gap-4 mt-4">
            <input
              type="text"
              name="firstName"
              value={formData.badges[index].firstName}
              onChange={(e) => handleChange(e, 'badge', index)}
              placeholder="First Name"
              required
              className="border p-2 rounded"
            />
            <input
              type="text"
              name="lastName"
              value={formData.badges[index].lastName}
              onChange={(e) => handleChange(e, 'badge', index)}
              placeholder="Last Name"
              required
              className="border p-2 rounded"
            />
            <input
              type="email"
              name="email"
              value={formData.badges[index].email}
              onChange={(e) => handleChange(e, 'badge', index)}
              placeholder="Email Address"
              required
              className="border p-2 rounded"
            />
            <input
              type="tel"
              name="mobile"
              value={formData.badges[index].mobile}
              onChange={(e) => handleChange(e, 'badge', index)}
              placeholder="Mobile Number"
              required
              className="border p-2 rounded"
            />
            <input
              type="text"
              name="company"
              value={formData.badges[index].company}
              onChange={(e) => handleChange(e, 'badge', index)}
              placeholder="Company"
              required
              className="border p-2 rounded"
            />
            <input
              type="text"
              name="position"
              value={formData.badges[index].position}
              onChange={(e) => handleChange(e, 'badge', index)}
              placeholder="Position"
              required
              className="border p-2 rounded"
            />
            <input
              type="text"
              name="streetAddress"
              value={formData.badges[index].streetAddress}
              onChange={(e) => handleChange(e, 'badge', index)}
              placeholder="Street Address"
              required
              className="border p-2 rounded col-span-2"
            />
            <input
              type="text"
              name="city"
              value={formData.badges[index].city}
              onChange={(e) => handleChange(e, 'badge', index)}
              placeholder="City"
              required
              className="border p-2 rounded"
            />
            <input
              type="text"
              name="state"
              value={formData.badges[index].state}
              onChange={(e) => handleChange(e, 'badge', index)}
              placeholder="State/Province"
              className="border p-2 rounded"
            />
            <select
              name="country"
              value={formData.badges[index].country}
              onChange={(e) => handleChange(e, 'badge', index)}
              required
              className="border p-2 rounded"
            >
              <option value="">Select Country</option>
              {countries.map((country) => (
                <option key={country} value={country}>
                  {country}
                </option>
              ))}
            </select>
           
            <div className="col-span-2">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  name="isPlaceholder"
                  checked={formData.badges[index].isPlaceholder}
                  onChange={(e) => handleChange(e, 'badge', index)}
                  className="mr-2"
                />
                <span>This is placeholder information</span>
              </label>
            </div>
            {formData.badges[index].isPlaceholder && (
              <input
                type="text"
                name="placeholderName"
                value={formData.badges[index].placeholderName}
                onChange={(e) => handleChange(e, 'badge', index)}
                placeholder="Place Holder Name"
                className="border p-2 rounded col-span-2"
              />
            )}
          </div>
          <div className="mt-4">
            <h4 className="text-lg font-bold mb-2">Badge Preview</h4>
            <div className="border p-4 rounded" style={{ 
              backgroundImage: `url('/AFFF-badge.png')`, 
              backgroundSize: 'cover', 
              backgroundPosition: 'center', 
              width: '300px', 
              height: '400px', 
              position: 'relative' 
            }}>
              <div className="absolute font-lexend text-center" style={{ 
                top: '210px', 
                left: '50px', 
                width: '194px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}>
                <p style={{ 
                  fontWeight: 600, 
                  fontSize: '15px', 
                  color: '#212121',
                  textTransform: 'capitalize',
                }}>
                  {formData.badges[index].isPlaceholder 
                    ? formData.badges[index].placeholderName 
                    : `${formData.badges[index].firstName} ${formData.badges[index].lastName}`}
                </p>
                <p style={{ 
                  fontWeight: 300, 
                  fontSize: '14px', 
                  color: '#212121',
                  fontStyle: 'italic',
                  textTransform: 'lowercase',
                }}>
                  {formData.badges[index].position}
                </p>
                <p style={{ 
                  fontWeight: 800, 
                  fontSize: '15px', 
                  color: '#5D5618',
                  textTransform: 'uppercase'
                }}>
                  {formData.badges[index].company}
                </p>
              </div>
            </div>
          </div>
        </div>
      ))}

      <div className='text-base font-base'>
        <label className="flex items-center">
          <input
            type="checkbox"
            name="accessibilityServices"
            onChange={(e) => handleChange(e)}
            className="mr-2"
          />
          <span className="text-sm text-gray-600">I will need accessibility services or special accommodations at the event.</span>
        </label>
      </div>

      <div className="text-sm text-gray-600">
        <label className="flex items-center">
          <input
            type="checkbox"
            name="agreeSponsors"
            checked={formData.agreeSponsors}
            onChange={(e) => handleChange(e)}
            className="mr-2"
          />
          I agree to Sponsors communication (optional)
        </label>
      </div>

      <div>
        <label className="flex items-center">
          <input
            type="checkbox"
            name="agreeTerms"
            checked={formData.agreeTerms}
            onChange={(e) => handleChange(e)}
            required
            className="mr-2"
          />
          <span className="text-sm text-gray-600">I agree to the AFFF 2024 Registration Terms and Conditions</span>
        </label>
      </div>

      <p className="text-sm text-gray-600">
        We're committed to your privacy. AFFF uses the information you provide to contact you about AFFF & other AFFF events. You may unsubscribe from these communications at any time. For more information, check out our Privacy Policy.
      </p>

      <div className="flex justify-between">
        <button
          type="button"
          onClick={onBack}
          className="bg-gray-300 text-black px-4 py-2 rounded hover:bg-gray-400 transition duration-300"
          disabled={isSubmitting}
        >
          Back
        </button>
        <button
          type="submit"
          className="bg-custom-red text-white px-4 py-2 rounded hover:bg-red-600 transition duration-300"
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Submitting...' : 'Review Order'}
        </button>
      </div>
      {error && <p className="text-red-500 mt-4">{error}</p>}
    </form>
  );
};

export default PersonalInfoForm;