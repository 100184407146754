import React, { useState } from 'react';
import { FaCheck, FaTimes, FaChevronDown, FaChevronUp } from 'react-icons/fa';

const FeatureComparisonTable = ({ category, convertCurrency }) => {
  const badges = category.badges.sort((a, b) => a.price - b.price);
  const allFeatures = [...new Set(badges.flatMap(badge => badge.features))];

  return (
    <div className="overflow-x-auto mt-8">
      <table className="min-w-full bg-white border-collapse">
        <thead>
          <tr className="bg-gray-100">
            <th className="px-4 py-2 text-left font-roboto">Feature</th>
            {badges.map(badge => (
              <th key={badge.name} className="px-4 py-2 text-center font-roboto">
                <div className="font-bold">{badge.name}</div>
                <div className="text-custom-red">{convertCurrency(badge.price)}</div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {allFeatures.map((feature, index) => (
            <tr key={index} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
              <td className="px-4 py-2 font-medium font-lexend border">{feature}</td>
              {badges.map((badge, badgeIndex) => (
                <td key={`${badge.name}-${index}`} className="px-4 py-2 text-center border">
                  {badge.features.includes(feature) || badges.slice(0, badgeIndex).some(b => b.features.includes(feature)) ? 
                    <FaCheck className="text-green-500 mx-auto" /> : 
                    <FaTimes className="text-red-500 mx-auto" />
                  }
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const BadgeCard = ({ badge, addToCart, convertCurrency, isGreatValue }) => {
  const [showAllFeatures, setShowAllFeatures] = useState(false);
  const displayedFeatures = showAllFeatures ? badge.features : badge.features.slice(0, 8);

  const isSpecialBadge = badge.name === "PREMIUM FILM INDUSTRY BADGE" || badge.name === "PREMIUM ELITE PLUS FINANCIER INDUSTRY BADGE";

  return (
    <div className={`bg-white rounded-md shadow-lg overflow-hidden h-full flex flex-col ${isSpecialBadge ? 'bg-blue-50' : ''} relative`}>
      {isGreatValue && (
        <div className="bg-yellow-400 font-roboto text-black font-bold py-3  px-20 absolute top-0 right-0 transform rotate-45 translate-x-1/3 translate-y-1/4 z-10">
        10% OFF
        </div>
      )}
      <div className="p-6 flex flex-col flex-grow">
        <h4 className="text-2xl font-bold mb-2 font-roboto leading-tight">{badge.name}</h4>
        <p className="text-3xl font-bold text-custom-red mb-2 font-lexend">{convertCurrency(badge.price)}</p>
        <p className="text-sm mb-4 font-lexend">{badge.details}</p>
        <ul className="mb-6 flex-grow overflow-y-auto custom-scrollbar font-lexend text-sm">
          {displayedFeatures.map((feature, j) => (
            <li key={j} className="flex items-start mb-2">
              <FaCheck className="w-4 h-4 mr-2 text-green-500 flex-shrink-0 mt-1" />
              <span>{feature}</span>
            </li>
          ))}
        </ul>
        {badge.features.length > 8 && (
          <button 
            className="text-custom-red font-bold mb-4"
            onClick={() => setShowAllFeatures(!showAllFeatures)}
          >
            {showAllFeatures ? (
              <>
                <FaChevronUp className="inline mr-1" />
                Show Less
              </>
            ) : (
              <>
                <FaChevronDown className="inline mr-1" />
                Show More
              </>
            )}
          </button>
        )}
      </div>
      <div className="p-6 pt-0">
        <button 
          className="w-full bg-custom-red text-white py-2 rounded-md font-bold hover:bg-red-600 transition duration-300"
          onClick={() => addToCart(badge)}
        >
          Get Badge
        </button>
      </div>
    </div>
  );
};

const BadgesSection = ({ addToCart, currency, showFeaturesComparison, toggleFeaturesComparison, convertCurrency }) => {
  const badgeCategories = [
    {
      category: "FILM INDUSTRY",
      badges: [
        {
          name: "INDUSTRY/STUDENT BADGE",
          price: 0,
          details: "(FREE - Limited to 100 slots)",
          features: [
            "Limited access to engage with key stakeholders and thought leaders in a high-impact forum at AFFF Industry forum session",
            "Enjoy red-carpet privileges at AFFF",
            "Meal from the AFFF Lounge is available for individual purchase",
            "Witness cutting-edge demonstrations of revolutionary film technologies and services at the AFFF Exhibition/Showcase",
            "Discover new film sites and collaborate with tourist sites at AFFF CineTour for your next film project"
          ]
        },
        {
          name: "PREMIUM FILM INDUSTRY BADGE",
          price: 60,
          details: "(1 BADGE)",
          features: [
            "Enjoy premium opportunities at AFFF",
            "Enjoy elite red-carpet privileges at AFFF",
            "Get one-on-one consultations with top financial advisors and industry experts to tailor strategies for your unique needs at AFFF Investors Room",
            "Get direct access to AFFF financiers and investors for engaging in confidential negotiations and acquiring strategic investment insights at AFFF Investors Room",
            "Maximize your project's funding potential at AFFF Investors Room",
            "Enjoy a premium networking environment with top-tier investors and industry leaders at AFFF Investors Lounge",
            "Complimentary tea and coffee breaks at the AFFF Investors Lounge",
            "Complimentary lunch at the AFFF Investors Lounge",
            "Access bespoke investment opportunities aligned with your strategic goals at the AFFF Investors Room",
            "Benefit from matchmaking services to connect with AFFF accredited seasoned financiers and investors at AFFF Lounge",
            "Experience engaging and diverse entertainment throughout the forum",
            "Access Insights on Maximizing Your ROI in Film Financing Forum session",
            "Participate in VIP think tanks and brainstorming sessions with industry leaders from the film and finance industry at the Forum",
            "Access to insight of potential investment avenues and funding opportunities for your film projects at AFFF Finance Forum",
            "Enjoy best seat reservation at the AFFF Film Financing Forum",
            "Learn how to collaborate with other sectors for film financing at AFFF industry integration session",
            "Gain access to Industry leaders from other sector such as sustainability, health, military, tourism etc. for film collaboration at AFFF industry integration session",
            "Access to extensive resources and learning materials designed to expand your knowledge and expertise of the film financing at AFFF Master Class",
            "Benefit from expert-led sessions and class that enhance your investment strategies and knowledge at the AFFF Master Class",
            "Stand a chance to compete in the AFFF Pitch Competition",
            "Attend AFFF private dinners and cocktails designed for deep, impactful networking",
            "Witness cutting-edge demonstrations of revolutionary film technologies and services at the AFFF Exhibition/Showcase",
            "Discover new film sites and collaborate with tourist sites at AFFF CineTour for your next film project",
            "Access to AFFF Film Premier",
            "Enjoy the recognition and prestige associated with elite status of Premium Film Industry professional at the AFFF event",
            "Benefit from enhanced media exposure and promotional opportunities at AFFF event",
            "Enjoy the opportunity to book meetings with AFFF key stakeholders and attendees",
            "Engage with key stakeholders and thought leaders in a high-impact forum at the AFFF event",
            "Win exclusive prizes through our thrilling raffles at AFFF",
            "Receive a custom event itinerary based on your interests and goals",
            "Attend invitation-only receptions with key stakeholders at AFFF",
            "Amplify your presence and influence among top finance industry professionals at AFFF",
            "Dedicated VIP check-in at AFFF"
          ]
        },
        {
          name: "PREMIUM ELITE FILM INDUSTRY BADGE",
          price: 90,
          details: "(1 BADGE)",
          features: [
            "Enjoy elite opportunities at AFFF",
            "Enjoy elite red-carpet privileges at AFFF",
            "Get one-on-one consultations with top financial advisors and industry experts to tailor strategies for your unique needs at AFFF Investors Room",
            "Get direct access to AFFF financiers and investors for engaging in confidential negotiations and acquiring strategic investment insights at AFFF Investors Room",
            "Maximize your project's funding potential at AFFF Investors Room",
            "Be invited to contribute to advisory boards that shape future industry developments and investment strategies at AFFF Private Executive Film Investment Roundtable",
            "Establish impactful connections with AFFF sponsors, opening doors to new opportunities and collaborations at AFFF Private Executive Film Investment Roundtable",
            "Participate in an in-depth analysis of market trends and emerging opportunities at AFFF Private Executive Film Investment Roundtable",
            "Become an active member of committees shaping the future of film finance at AFFF Private Executive Film Investment Roundtable",
            "Enjoy a premium networking environment with top-tier investors and industry leaders at AFFF Investors Lounge",
            "Complimentary tea and coffee breaks at the AFFF Investors Lounge",
            "Complimentary lunch at the AFFF Investors Lounge",
            "Access bespoke investment opportunities aligned with your strategic goals at the AFFF Investors Room",
            "Benefit from matchmaking services to connect with AFFF accredited seasoned financiers and investors at AFFF Lounge",
            "Experience engaging and diverse entertainment throughout the forum",
            "Access Insights on Maximizing Your ROI in Film Financing Forum session",
            "Participate in VIP think tanks and brainstorming sessions with industry leaders from the film and finance industry at the Forum",
            "Access to insight of potential investment avenues and funding opportunities for your film projects at AFFF Finance Forum",
            "Enjoy best seat reservation at the AFFF Film Financing Forum",
            "Learn how to collaborate with other sectors for film financing at AFFF industry integration session",
            "Gain access to Industry leaders from other sector such as sustainability, health, military, tourism etc. for film collaboration at AFFF industry integration session",
            "Access to extensive resources and learning materials designed to expand your knowledge and expertise of the film financing at AFFF Master Class",
            "Benefit from expert-led sessions and class that enhance your investment strategies and knowledge at the AFFF Master Class",
            "Get certification from a premium film academy at the AFFF Master Class",
            "Stand a chance to compete in the AFFF Pitch Competition",
            "Attend AFFF private dinners and cocktails designed for deep, impactful networking",
            "Witness cutting-edge demonstrations of revolutionary film technologies and services at the AFFF Exhibition/Showcase",
            "Discover new film sites and collaborate with tourist sites at AFFF CineTour for your next film project",
            "Access to AFFF Film Premier",
            "Enjoy the recognition and prestige associated with elite status of Premium Elite Film Industry professional at the AFFF event",
            "Benefit from a reserved VIP parking spot at the AFFF venue for unparalleled convenience and ease",
            "Benefit from enhanced media exposure and promotional opportunities at AFFF event",
            "Enjoy the opportunity to book meetings with AFFF key stakeholders and attendees",
            "Engage with key stakeholders and thought leaders in a high-impact forum at the AFFF event",
            "Win exclusive prizes through our thrilling raffles at AFFF",
            "Receive a custom event itinerary based on your interests and goals",
            "Attend invitation-only receptions with key stakeholders at AFFF",
            "Amplify your presence and influence among top finance industry professionals at AFFF",
            "Dedicated VIP check-in at AFFF"
          ]
        },
        {
          name: "PREMIUM ELITE PLUS FILM INDUSTRY BADGE",
          price: 150,
          details: "(1 BADGE)",
          features: [
            "Access entry to restricted areas and elite events at AFFF",
            "Enjoy exceptional VIP opportunities at AFFF",
            "Enjoy elite red-carpet privileges at AFFF",
            "Gain privileged access to high-stakes negotiations and investment opportunities at AFFF Deal Table",
            "Stand a chance to Present Your Project to AFFF top Tier accredited Financier from private equity, hedge funders, Angel Investors, Premium Bank CEO in the AFFF Deal Room",
            "Get one-on-one consultations with top financial advisors and industry experts to tailor strategies for your unique needs at AFFF Investors Room",
            "Get direct access to AFFF financiers and investors for engaging in confidential negotiations and acquiring strategic investment insights at AFFF Investors Room",
            "Maximize your project's funding potential at AFFF Investors Room",
            "Be invited to contribute to advisory boards that shape future industry developments and investment strategies at AFFF Private Executive Film Investment Roundtable",
            "Establish impactful connections with AFFF sponsors, opening doors to new opportunities and collaborations at AFFF Private Executive Film Investment Roundtable",
            "Participate in an in-depth analysis of market trends and emerging opportunities at AFFF Private Executive Film Investment Roundtable",
            "Become an active member of committees shaping the future of film finance at AFFF Private Executive Film Investment Roundtable",
            "Enjoy a premium networking environment with top-tier investors and industry leaders at AFFF Investors Lounge",
            "Complimentary tea and coffee breaks at the AFFF Investors Lounge",
            "Complimentary lunch at the AFFF Investors Lounge",
            "Access bespoke investment opportunities aligned with your strategic goals at the AFFF Investors Room",
            "Benefit from matchmaking services to connect with AFFF accredited seasoned financiers and investors at AFFF Lounge",
            "Experience engaging and diverse entertainment throughout the forum",
            "Access Insights on Maximizing Your ROI in Film Financing Forum session",
            "Participate in VIP think tanks and brainstorming sessions with industry leaders from the film and finance industry at the Forum",
            "Access to insight of potential investment avenues and funding opportunities for your film projects at AFFF Finance Forum",
            "Enjoy best seat reservation at the AFFF Film Financing Forum",
            "Learn how to collaborate with other sectors for film financing at AFFF industry integration session",
            "Gain access to Industry leaders from other sector such as sustainability, health, military, tourism etc. for film collaboration at AFFF industry integration session",
            "Access to extensive resources and learning materials designed to expand your knowledge and expertise of the film financing at AFFF Master Class",
            "Benefit from expert-led sessions and class that enhance your investment strategies and knowledge at the AFFF Master Class",
            "Get certification from a premium film academy at the AFFF Master Class",
            "Stand a chance to compete in the AFFF Pitch Competition",
            "Attend AFFF private dinners and cocktails designed for deep, impactful networking",
            "Witness cutting-edge demonstrations of revolutionary film technologies and services at the AFFF Exhibition/Showcase",
            "Discover new film sites and collaborate with tourist sites at AFFF CineTour for your next film project",
            "Access to AFFF Film Premier",
            "Enjoy the recognition and prestige associated with elite status of Premium Elite Plus Film Industry professional at the AFFF event",
            "Benefit from a reserved VIP parking spot at the AFFF venue for unparalleled convenience and ease",
            "Benefit from enhanced media exposure and promotional opportunities at AFFF event",
            "Enjoy the opportunity to book meetings with AFFF key stakeholders and attendees",
            "Engage with key stakeholders and thought leaders in a high-impact forum at the AFFF event",
            "Win exclusive prizes through our thrilling raffles at AFFF",
            "Receive a custom event itinerary based on your interests and goals",
            "Attend invitation-only receptions with key stakeholders at AFFF",
            "Amplify your presence and influence among top finance industry professionals at AFFF",
            "Dedicated VIP check-in at AFFF"
          ]
        }
      ]
    },
    {
      category: "FINANCE INDUSTRY",
      badges: [
        {
          name: "PREMIUM FINANCIER INDUSTRY BADGE",
          price: 60,
          details: "(1 BADGE)",
          features: [
            "Enjoy premium opportunities at AFFF",
            "Enjoy red-carpet privileges at AFFF",
            "Get one-on-one conversations with top film industry experts with tailored strategic projects for your unique needs at AFFF Investors Room",
            "Get direct access to AFFF accredited film industry professionals for engaging in confidential negotiations and acquiring strategic investment insights at AFFF Investors Room",
            "Promote your project's funding offers to AFFF accredited film industry professionals at AFFF Investors Room",
            "Enjoy a premium networking environment with top-tier investors and industry leaders at AFFF Investors Lounge",
            "Complimentary tea and coffee breaks at the AFFF Investors Lounge",
            "Complimentary lunch at the AFFF Investors Lounge",
            "Access bespoke investment opportunities aligned with your strategic goals at the AFFF Investors Room",
            "Benefit from matchmaking services to connect with AFFF accredited seasoned industry experts from diverse sectors; tourism, sustainability, sports, military etc. at AFFF Lounge",
            "Experience engaging and diverse entertainment throughout the forum",
            "Access Insights on Maximizing Your ROI in Film Financing Forum session",
            "Participate in VIP think tanks and brainstorming sessions with industry leaders from the film and finance industry at the Forum",
            "Access to insight of potential investment opportunities of film projects at AFFF Finance Forum",
            "Enjoy best seat reservation at the AFFF Film Financing Forum",
            "Learn how to collaborate with other sectors for film financing at AFFF industry integration session",
            "Gain access to Industry leaders from other sector such as sustainability, health, military, tourism etc. for film collaboration at AFFF industry integration session",
            "Access to extensive resources and learning materials designed to expand your knowledge and expertise of the film financing at AFFF Master Class",
            "Benefit from expert-led sessions and class that enhance your investment strategies and knowledge at the AFFF Master Class",
            "Get certification from a premium film finance academy at the AFFF Master Class",
            "Get privileged access to film projects from accredited AFFF film industry professionals at AFFF Pitch Competition",
            "Attend AFFF private dinners and cocktails designed for deep, impactful networking",
            "Witness cutting-edge demonstrations of revolutionary film technologies and services at the AFFF Exhibition/Showcase",
            "Gain privileged access to build valuable relationships with tourism boards and location managers, creating opportunities for future collaborations and joint ventures at AFFF CineTour",
            "Access to AFFF Film Premier",
            "Enjoy the recognition and prestige associated with elite status of Premium Elite Financier Industry professional at the AFFF event",
            "Benefit from a reserved VIP parking spot at the AFFF venue for unparalleled convenience and ease",
            "Benefit from enhanced media exposure and promotional opportunities at AFFF event",
            "Enjoy the opportunity to book meetings with AFFF key stakeholders and attendees",
            "Engage with key stakeholders and thought leaders in a high-impact forum at the AFFF event",
            "Win exclusive prizes through our thrilling raffles at AFFF",
            "Receive a custom event itinerary based on your interests and goals",
            "Attend invitation-only receptions with key stakeholders at AFFF",
            "Amplify your presence and influence among top film industry professionals at AFFF",
            "Dedicated VIP check-in at AFFF"
          ]
        },
        {
          name: "PREMIUM ELITE FINANCIER INDUSTRY BADGE",
          price: 90,
          details: "(1 BADGE)",
          features: [
            "Enjoy elite opportunities at AFFF",
            "Enjoy elite red-carpet privileges at AFFF",
            "Get one-on-one conversations with top film industry experts with tailored strategic projects for your unique needs at AFFF Investors Room",
            "Get direct access to AFFF accredited film industry professionals for engaging in confidential negotiations and acquiring strategic investment insights at AFFF Investors Room",
            "Promote your project's funding offers to AFFF accredited film industry professionals at AFFF Investors Room",
            "Be invited to contribute to advisory boards that shape future industry developments and investment strategies at AFFF Private Executive Film Investment Roundtable",
            "Establish impactful connections with AFFF stakeholders, opening doors to new opportunities and collaborations at AFFF Private Executive Film Investment Roundtable",
            "Participate in an in-depth analysis of market trends and emerging opportunities at AFFF Private Executive Film Investment Roundtable",
            "Become an active member of committees shaping the future of film finance at AFFF Private Executive Film Investment Roundtable",
            "Enjoy a premium networking environment with top-tier investors and industry leaders at AFFF Investors Lounge",
            "Complimentary tea and coffee breaks at the AFFF Investors Lounge",
            "Complimentary lunch at the AFFF Investors Lounge",
            "Access bespoke investment opportunities aligned with your strategic goals at the AFFF Investors Room",
            "Benefit from matchmaking services to connect with AFFF accredited seasoned industry experts from diverse sectors; tourism, sustainability, sports, military etc. at AFFF Lounge",
            "Experience engaging and diverse entertainment throughout the forum",
            "Access Insights on Maximizing Your ROI in Film Financing Forum session",
            "Participate in VIP think tanks and brainstorming sessions with industry leaders from the film and finance industry at the Forum",
            "Access to insight of potential investment opportunities of film projects at AFFF Finance Forum",
            "Enjoy best seat reservation at the AFFF Film Financing Forum",
            "Learn how to collaborate with other sectors for film financing at AFFF industry integration session",
            "Gain access to Industry leaders from other sector such as sustainability, health, military, tourism etc. for film collaboration at AFFF industry integration session",
            "Access to extensive resources and learning materials designed to expand your knowledge and expertise of the film financing at AFFF Master Class",
            "Benefit from expert-led sessions and class that enhance your investment strategies and knowledge at the AFFF Master Class",
            "Get certification from a premium film finance academy at the AFFF Master Class",
            "Get privileged access to film projects from accredited AFFF film industry professionals at AFFF Pitch Competition",
            "Attend AFFF private dinners and cocktails designed for deep, impactful networking",
            "Witness cutting-edge demonstrations of revolutionary film technologies and services at the AFFF Exhibition/Showcase",
            "Gain privileged access to build valuable relationships with tourism boards and location managers, creating opportunities for future collaborations and joint ventures at AFFF CineTour",
            "Access to AFFF Film Premier",
            "Enjoy the recognition and prestige associated with elite status of Premium Elite Financier Industry professional at the AFFF event",
            "Benefit from a reserved VIP parking spot at the AFFF venue for unparalleled convenience and ease",
            "Benefit from enhanced media exposure and promotional opportunities at AFFF event",
            "Enjoy the opportunity to book meetings with AFFF key stakeholders and attendees",
            "Engage with key stakeholders and thought leaders in a high-impact forum at the AFFF event",
            "Win exclusive prizes through our thrilling raffles at AFFF",
            "Receive a custom event itinerary based on your interests and goals",
            "Attend invitation-only receptions with key stakeholders at AFFF",
            "Amplify your presence and influence among top film industry professionals at AFFF",
            "Dedicated VIP check-in at AFFF"
          ]
        },
        {
          name: "PREMIUM ELITE PLUS FINANCIER INDUSTRY BADGE",
          price: 150,
          details: "(1 BADGE)",
          features: [
            "Access entry to restricted areas and elite events at AFFF",
            "Enjoy exceptional VIP opportunities at AFFF",
            "Enjoy elite red-carpet privileges at AFFF",
            "Gain privileged access to high-stakes projects and investment opportunities from AFFF accredited film industry professionals with assured ROI at AFFF Deal Table",
            "Get one-on-one conversations with top film industry experts with tailored strategic projects for your unique needs at AFFF Investors Room",
            "Get direct access to AFFF accredited film industry professionals for engaging in confidential negotiations and acquiring strategic investment insights at AFFF Investors Room",
            "Promote your project's funding offers to AFFF accredited film industry professionals at AFFF Investors Room",
            "Be invited to contribute to advisory boards that shape future industry developments and investment strategies at AFFF Private Executive Film Investment Roundtable",
            "Establish impactful connections with AFFF stakeholders, opening doors to new opportunities and collaborations at AFFF Private Executive Film Investment Roundtable",
            "Participate in an in-depth analysis of market trends and emerging opportunities at AFFF Private Executive Film Investment Roundtable",
            "Become an active member of committees shaping the future of film finance at AFFF Private Executive Film Investment Roundtable",
            "Enjoy a premium networking environment with top-tier investors and industry leaders at AFFF Investors Lounge",
            "Complimentary tea and coffee breaks at the AFFF Investors Lounge",
            "Complimentary lunch at the AFFF Investors Lounge",
            "Access bespoke investment opportunities aligned with your strategic goals at the AFFF Investors Room",
            "Benefit from matchmaking services to connect with AFFF accredited seasoned industry experts from diverse sectors; tourism, sustainability, sports, military etc. at AFFF Lounge",
            "Experience engaging and diverse entertainment throughout the forum",
            "Access Insights on Maximizing Your ROI in Film Financing Forum session",
            "Participate in VIP think tanks and brainstorming sessions with industry leaders from the film and finance industry at the Forum",
            "Access to insight of potential investment opportunities of film projects at AFFF Finance Forum",
            "Enjoy best seat reservation at the AFFF Film Financing Forum",
            "Learn how to collaborate with other sectors for film financing at AFFF industry integration session",
            "Gain access to Industry leaders from other sector such as sustainability, health, military, tourism etc. for film collaboration at AFFF industry integration session",
            "Access to extensive resources and learning materials designed to expand your knowledge and expertise of the film financing at AFFF Master Class",
            "Benefit from expert-led sessions and class that enhance your investment strategies and knowledge at the AFFF Master Class",
            "Get certification from a premium film finance academy at the AFFF Master Class",
            "Get privileged access to film projects from accredited AFFF film industry professionals at AFFF Pitch Competition",
            "Attend AFFF private dinners and cocktails designed for deep, impactful networking",
            "Witness cutting-edge demonstrations of revolutionary film technologies and services at the AFFF Exhibition/Showcase",
            "Gain privileged access to build valuable relationships with tourism boards and location managers, creating opportunities for future collaborations and joint ventures at AFFF CineTour",
            "Access to AFFF Film Premier",
            "Enjoy the recognition and prestige associated with elite status of Premium Elite Plus Financier Industry professional at the AFFF event",
            "Benefit from a reserved VIP parking spot at the AFFF venue for unparalleled convenience and ease",
            "Benefit from enhanced media exposure and promotional opportunities at AFFF event",
            "Enjoy the opportunity to book meetings with AFFF key stakeholders and attendees",
            "Engage with key stakeholders and thought leaders in a high-impact forum at the AFFF event",
            "Win exclusive prizes through our thrilling raffles at AFFF",
            "Receive a custom event itinerary based on your interests and goals",
            "Attend invitation-only receptions with key stakeholders at AFFF",
            "Amplify your presence and influence among top film industry professionals at AFFF",
            "Dedicated VIP check-in at AFFF"
          ]
        },
        {
          name: "CORPORATE PLAN",
          price: 2000,
          details: "(OWNING AN AFFF INVESTORS ROOM AND TWO PREMIUM ELITE PLUS FINANCIER INDUSTRY BADGES)",
          features: [
            "Own an AFFF Investors Room to showcase your financiers' offers to over 3,000 participants from the film and finance industries",
            "Access entry to restricted areas and elite events at AFFF",
            "Enjoy exceptional VIP opportunities at AFFF",
            "Enjoy elite red-carpet privileges at AFFF",
            "Gain privileged access to high-stakes projects and investment opportunities from AFFF accredited film industry professionals with assured ROI at AFFF Deal Table",
            "Get one-on-one conversations with top film industry experts with tailored strategic projects for your unique needs at AFFF Investors Room",
            "Get direct access to AFFF accredited film industry professionals for engaging in confidential negotiations and acquiring strategic investment insights at AFFF Investors Room",
            "Promote your project's funding offers to AFFF accredited film industry professionals at AFFF Investors Room",
            "Be invited to contribute to advisory boards that shape future industry developments and investment strategies at AFFF Private Executive Film Investment Roundtable",
            "Establish impactful connections with AFFF stakeholders, opening doors to new opportunities and collaborations at AFFF Private Executive Film Investment Roundtable",
            "Participate in an in-depth analysis of market trends and emerging opportunities at AFFF Private Executive Film Investment Roundtable",
            "Become an active member of committees shaping the future of film finance at AFFF Private Executive Film Investment Roundtable",
            "Enjoy a premium networking environment with top-tier investors and industry leaders at AFFF Investors Lounge",
            "Complimentary tea and coffee breaks at the AFFF Investors Lounge",
            "Complimentary lunch at the AFFF Investors Lounge",
            "Access bespoke investment opportunities aligned with your strategic goals at the AFFF Investors Room",
            "Benefit from matchmaking services to connect with AFFF accredited seasoned industry experts from diverse sectors; tourism, sustainability, sports, military etc. at AFFF Lounge",
            "Experience engaging and diverse entertainment throughout the forum",
            "Access Insights on Maximizing Your ROI in Film Financing Forum session",
            "Participate in VIP think tanks and brainstorming sessions with industry leaders from the film and finance industry at the Forum",
            "Access to insight of potential investment opportunities of film projects at AFFF Finance Forum",
            "Enjoy best seat reservation at the AFFF Film Financing Forum",
            "Learn how to collaborate with other sectors for film financing at AFFF industry integration session",
            "Gain access to Industry leaders from other sector such as sustainability, health, military, tourism etc. for film collaboration at AFFF industry integration session",
            "Access to extensive resources and learning materials designed to expand your knowledge and expertise of the film financing at AFFF Master Class",
            "Benefit from expert-led sessions and class that enhance your investment strategies and knowledge at the AFFF Master Class",
            "Get certification from a premium film finance academy at the AFFF Master Class",
            "Get privileged access to film projects from accredited AFFF film industry professionals at AFFF Pitch Competition",
            "Attend AFFF private dinners and cocktails designed for deep, impactful networking",
           "Witness cutting-edge demonstrations of revolutionary film technologies and services at the AFFF Exhibition/Showcase",
            "Gain privileged access to build valuable relationships with tourism boards and location managers, creating opportunities for future collaborations and joint ventures at AFFF CineTour",
            "Access to AFFF Film Premier",
            "Enjoy the recognition and prestige associated with elite status of Premium Elite Plus Financier Industry professional at the AFFF event",
            "Benefit from a reserved VIP parking spot at the AFFF venue for unparalleled convenience and ease",
            "Benefit from enhanced media exposure and promotional opportunities at AFFF event",
            "Enjoy the opportunity to book meetings with AFFF key stakeholders and attendees",
            "Engage with key stakeholders and thought leaders in a high-impact forum at the AFFF event",
            "Win exclusive prizes through our thrilling raffles at AFFF",
            "Receive a custom event itinerary based on your interests and goals",
            "Attend invitation-only receptions with key stakeholders at AFFF",
            "Amplify your presence and influence among top film industry professionals at AFFF",
            "Dedicated VIP check-in at AFFF"
          ]
        }
      ]
    }
  ];

  const renderBadges = (badges) => {
    const rows = [];
    for (let i = 0; i < badges.length; i += 3) {
      const rowBadges = badges.slice(i, i + 3);
      rows.push(
        <div key={i} className={`grid gap-6 mb-6 ${rowBadges.length === 1 ? 'grid-cols-1' : rowBadges.length === 2 ? 'grid-cols-1 md:grid-cols-2' : 'grid-cols-1 md:grid-cols-2 lg:grid-cols-3'}`}>
          {rowBadges.map((badge, index) => (
            <div key={index} className={rowBadges.length === 1 ? 'col-span-full' : ''}>
              <BadgeCard
                badge={badge}
                addToCart={addToCart}
                convertCurrency={convertCurrency}
                isGreatValue={badge.name === "PREMIUM ELITE FILM INDUSTRY BADGE" || badge.name === "PREMIUM ELITE PLUS FINANCIER INDUSTRY BADGE"}
              />
            </div>
          ))}
        </div>
      );
    }
    return rows;
  };

  return (
    <section className="mb-8 sm:mb-12 container mx-auto px-4">
      <h2 className="font-header text-[5rem] md:text-[7rem] text-center font-bold mb-6 tracking-wider text-custom-base">BADGES</h2>
      
      <div className="flex flex-col md:flex-row bg-[#FDF6EC] p-8 mb-8 shadow-lg rounded-lg">
        <div className="md:w-1/2 pr-8">
          <h3 className="font-header text-4xl md:text-[6rem] leading-none font-bold mb-4 text-navy-blue">EARLY BIRD REGISTRATION</h3>
          <p className="font-lexend mb-4">
            AFFF 2024 Early Bird Pass offers attendees the chance to secure their tickets at the lowest available rates. This limited-time opportunity allows forward-thinking professionals to reserve their spot for one of the industry's most anticipated marketing and sales events.
          </p>
          <p className="font-lexend mb-4">
            VIP tickets are not yet on sale. If you purchase a General Admission AFFF ticket, we can support in an upgrade to VIP upon onsite.
          </p>
          <p className="font-lexend">
            Check out our ticket prices and their associated perks. You can read more about our refund policy <a href="#" className="text-custom-red hover:underline">here</a>.
          </p>
        </div>
        <div className="md:w-1/2 bg-gradient-to-br from-teal-400 to-blue-500 p-6 text-white rounded-lg mt-4 md:mt-0">
          <h4 className="font-header text-2xl md:text-[3rem] tracking-widest font-bold">GENERAL ADMISSION PASS</h4>
          <p className="font-lexend mb-4 font-bold text-xl text-yellow-200">NOVEMBER 26 - 28, 2025 • LAGOS, NIGERIA</p>
          <ul className="mb-6">
            <li className="flex items-center mb-2">
              <FaCheck className="mr-2 flex-shrink-0" /> Access to all in-person content and programming
            </li>
            <li className="flex items-center mb-2">
              <FaCheck className="mr-2 flex-shrink-0" /> Special hotel rates and a seamless booking experience with concierge support (while inventory lasts)
            </li>
            <li className="flex items-center mb-2">
              <FaCheck className="mr-2 flex-shrink-0" /> Access to educational content, entertainment, attendee networking, and connection opportunities with our sponsors
            </li>
            <li className="flex items-center mb-2">
              <FaCheck className="mr-2 flex-shrink-0" /> Access to special AFFF offerings, including coffee and surprise and delight snacks
            </li>
            <li className="flex items-center mb-2">
              <FaCheck className="mr-2 flex-shrink-0" /> Lunch available for individual purchase
            </li>
          </ul>
          <p className="font-header tracking-wide text-shadow text-4xl font-bold mb-4">{convertCurrency(90)}</p>
          <button 
            className="bg-white text-teal-500 rounded shadow-md px-6 py-2 font-bold hover:bg-gray-100 transition duration-300"
            onClick={() => addToCart({ name: "GENERAL ADMISSION PASS", price: 90, details: "NOVEMBER 26 - 28, 2025 • LAGOS, NIGERIA" })}
          >
            GET BADGE
          </button>
        </div>
      </div>

      {badgeCategories.map((category, index) => (
        <div key={index} className="mb-8 sm:mb-12">
          <h3 className="font-roboto text-2xl sm:text-3xl mb-6 font-bold div-with-bold-shadow">{category.category}</h3>
          {renderBadges(category.badges.sort((a, b) => a.price - b.price))}
          <div className="flex justify-center mt-8">
            <button 
              onClick={() => toggleFeaturesComparison(category.category)}
              className="bg-custom-red text-white px-4 py-2 rounded hover:bg-red-600 transition duration-300"
            >
              {category.category === "FILM INDUSTRY" 
                ? "Compare Industry, Stakeholder and Enthusiasts Badges" 
                : "Compare Financier Badges"}
            </button>
          </div>
          {showFeaturesComparison === category.category && (
            <section className="text-left mt-16 mb-16 py-3 p-4 border border-gray-300 bg-white shadow-md rounded-lg">
              <h4 className="font-roboto text-xl sm:text-2xl font-bold mt-8 mb-4">Features Comparison</h4>
              <FeatureComparisonTable category={category} convertCurrency={convertCurrency} />
            </section>
          )}
        </div>
      ))}
    </section>
  );
};

export default BadgesSection;