// src/services/api.js
import axios from 'axios';

const API_URL = 'https://api.africafilmfinanceforum.com';

export const initiatePayment = async (email, amount, currency = 'USD', type, metadata) => {
  try {
    const response = await axios.post(`https://api.africafilmfinanceforum.com/payments/initiate`, { 
      email, 
      amount, 
      currency,
      type,
      metadata: {
        ...metadata,
        badgeCount: metadata.badgeCount,
        isAnnual: metadata.isAnnual
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error initiating payment:', error);
    throw error;
  }
};

export const verifyPayment = async (reference) => {
  try {
    const response = await axios.get(`https://api.africafilmfinanceforum.com/payments/verify/${reference}`);
    return response.data;
  } catch (error) {
    console.error('Error verifying payment:', error);
    throw error;
  }
};

export const submitContactForm = async (formData) => {
  try {
    const response = await axios.post(`https://api.africafilmfinanceforum.com/contact`, formData);
    return response.data;
  } catch (error) {
    console.error('Error submitting contact form:', error);
    throw error;
  }
};